import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Tabs } from "antd";
import { useMemo } from "react";
import { matchPath } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { useCreatePrintOption } from "shared/hooks/salesEnablement/useCreatePrintOption";
import { useUpdatePrintOption } from "shared/hooks/salesEnablement/useUpdatePrintOption";
import {
  PrintModeOption,
  PrintOptionCopyType,
  PrintOptionItem,
  UpdatePrintOptionPayload,
} from "shared/types/printOptions";
import { Template, TemplateStatus } from "shared/types/salesEnablement";
import { salesEnablementTheme } from "theme/salesEnablement";
import uuid, { v4 } from "uuid";
import { ROUTES } from "../constants";
import { AssociatedTemplates } from "./printOptionsForm/AssociatedTemplates";
import { PrintOptionsCostFieldTable } from "./printOptionsForm/PrintOptionsCostFieldTable";
import { PrintOptionsFields } from "./printOptionsForm/PrintOptionsFields";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";

export const PrintOptionsForm = ({
  formId,
  printOption,
  onClose,
}: {
  formId: string;
  printOption?: PrintOptionItem;
  onClose: () => void;
}) => {
  const { templates: allTemplates } = useFetchTemplates();
  const templatesWithPrintOption: Record<string, Template> = useMemo(() => {
    return allTemplates.reduce((acc, template) => {
      if (template.printOptionId) {
        return {
          ...acc,
          [template.id]: template,
        };
      }
      return acc;
    }, {});
  }, [allTemplates]);

  const onSuccess = (messageLabel: string) => {
    successNotification({
      messageLabel: <div>{messageLabel}</div>,
    });
  };
  const [form] = Form.useForm();
  const { mutate: createPrintOption } = useCreatePrintOption();
  const { mutateAsync: updatePrintOption } = useUpdatePrintOption();

  const defaultValues: Partial<PrintOptionItem> = {
    printMode: [PrintModeOption.SIMPLEX],
    associatedTemplateIds: [],
    copySelection: PrintOptionCopyType.RANGE,
    productionTime: { min: 1 },
    costPerCopy: [
      {
        id: uuid.v4(),
        quantity: { min: 1 },
        cost: {
          [PrintModeOption.SIMPLEX]: undefined,
          [PrintModeOption.DUPLEX]: undefined,
        },
      },
    ],
  };

  const onFinish = async (printOptionFormValue: PrintOptionItem) => {
    const id = printOption?.id;

    if (id) {
      const titleText = "Are you sure you want to edit this print option?";
      const detailText =
        "Your edit will automatically update in agents' existing and in-progress Marketing Materials.";
      Modal.confirm({
        title: <b>{titleText}</b>,
        content: detailText,
        onOk: async () => {
          const { associatedTemplateIds, ...updates } = printOptionFormValue;
          const addUpdates: UpdatePrintOptionPayload["templateUpdates"] =
            printOptionFormValue.associatedTemplateIds
              .filter(id => !printOption.associatedTemplateIds?.includes(id))
              .map(id => ({ id, op: "ADD" }));

          const rmUpdates: UpdatePrintOptionPayload["templateUpdates"] =
            printOption.associatedTemplateIds
              ?.filter(id => !associatedTemplateIds?.includes(id))
              .flatMap(id => {
                const option = templatesWithPrintOption?.[id];
                if (!option) return [];
                return {
                  id,
                  op: "REMOVE",
                  status: TemplateStatus["UNPUBLISHED"],
                  statusHistory: [
                    option.status,
                    ...(option.statusHistory ?? []),
                  ],
                };
              });

          const templateUpdates = [...addUpdates, ...rmUpdates];

          const editedPrintOption = {
            id: printOption.id,
            option: {
              ...updates,
              templateUpdates,
            },
          };
          await updatePrintOption(editedPrintOption);
          onSuccess("This print option have been successfully saved.");

          setTimeout(onClose, 50);
        },
        icon: (
          <ExclamationCircleOutlined
            style={{ color: salesEnablementTheme?.primaryColor }}
          />
        ),
        okText: "Confirm",
      });
      return;
    }
    createPrintOption(
      { ...printOptionFormValue, id: v4() },
      {
        onSuccess: () => {
          onSuccess("This print option has been successfully saved.");
          setTimeout(onClose, 50);
        },
        onError: onClose,
      },
    );
  };

  const isAssociatedTemplatesEnabled = !!matchPath(
    { path: ROUTES.edit(printOption?.id ?? "") },
    location.pathname,
  );

  const onFinishFailed = () => {
    errorNotification({
      messageLabel: "Please fix all errors before saving.",
    });
  };

  return (
    <Form
      id={formId}
      initialValues={printOption ?? defaultValues}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Tabs>
        <Tabs.TabPane tab="Settings" key="settings">
          <PrintOptionsFields form={form} />
          <PrintOptionsCostFieldTable />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Associated Templates"
          key="associatedTemplateIds"
          disabled={!isAssociatedTemplatesEnabled}
          forceRender
        >
          <Form.Item name="associatedTemplateIds" shouldUpdate>
            <AssociatedTemplates
              templatesWithPrintOption={templatesWithPrintOption}
              printOptionId={printOption?.id}
            />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};
