import { rbac } from "config/rbac";
import { useCheckPerms } from "./useCheckPerms";
import { useUser } from "./useUser";

export const useIsAdmin = () => useCheckPerms(rbac.manageAdmin);

export const useIsSalesEnablementAdmin = () =>
  useCheckPerms(rbac.manageSalesEnablementAdmin);

export const useIsConstellationTestingAccount = () => {
  const constellationEmailDomains = [
    "@helloconstellation.com",
    "@constellationagency.com",
  ];

  const { email } = useUser();
  const isAdmin = useCheckPerms(rbac.manageAdmin);
  const isConstellationTestingAccount = constellationEmailDomains.some(
    account => email.toLowerCase().endsWith(account),
  );

  return isAdmin || !!isConstellationTestingAccount;
};
