import { EditOutlined } from "@ant-design/icons";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { useFetchPrintOptions } from "shared/hooks/salesEnablement/useFetchPrintOptions";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { ToolbarButton } from "shared/types/adLibrary";
import {
  PrintOptionsTableColumns,
  PrintOptionTableItem,
} from "shared/types/printOptions";
import {
  DataProvider,
  useSalesEnablementContext,
} from "./hooks/SalesEnablementDataProvider";
import styles from "./PrintOptions.module.scss";
import { ROUTES } from "./printOptions/constants";
import { PrintOptionsDrawer } from "./printOptions/PrintOptionsDrawer";
import { PrintOptionsTable } from "./printOptions/PrintOptionsTable";
import { matchPath, useParams } from "react-router-dom";
import { printOptionsFields } from "./printOptions/printOptionsTable/fields";

export const PrintOptionsContainer = () => {
  const { printOptionId } = useParams();
  const navigate = useNavigateWithSearch();
  const isAdmin = useIsSalesEnablementAdmin();
  const { data: printOptions, isLoading } = useFetchPrintOptions();
  const { templates } = useSalesEnablementContext();

  const normalizedPrintOptions: PrintOptionTableItem[] =
    printOptions?.map(option => ({
      ...option,
      weight: option.weight?.toString() ?? "",
      associatedTemplateIds: templates
        .filter(template => template.printOptionId === option.id)
        .map(template => template.id),
    })) ?? [];

  const { data, selectedItems } = useDataListURLData<
    PrintOptionsTableColumns,
    PrintOptionTableItem
  >({
    isError: false,
    isLoading,
    data: normalizedPrintOptions ?? [],
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    PrintOptionsTableColumns,
    PrintOptionTableItem
  >();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const generateToolbarContents = (
    record?: PrintOptionTableItem,
  ): ToolbarButton => {
    if (!record || !isAdmin) return {};
    return {
      Edit: {
        icon: <EditOutlined />,
        showInInlineMenu: true,
        showInContextMenu: true,
        onClick: () => {
          navigate(ROUTES.edit(record.id));
        },
      },
    };
  };

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: !isAdmin,
      extraInfo: {
        text: "New Print Option",
        tooltip: "Create new Print Option",
      },
      onClick: () => {
        navigate(ROUTES.create);
      },
    },
    Edit: {
      disabled: selectedItems.length !== 1 || !isAdmin,
      showInInlineMenu: true,
      showInContextMenu: true,
      onClick: () => {
        navigate(ROUTES.edit(selectedItems[0].id));
      },
    },
  };

  const isPrintOptionsDrawerVisible =
    !!matchPath({ path: ROUTES.create }, location.pathname) ||
    !!(
      printOptionId &&
      matchPath({ path: ROUTES.edit(printOptionId) }, location.pathname)
    );

  return (
    <div className={styles.container}>
      <ToolbarTable
        titleTooltip="Search"
        toolbarContents={isAdmin ? toolbarContents : {}}
        searchPlaceholder="Search"
        onSearch={setGlobalFilter}
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        previewFormat={false}
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
        includeFilterOnTableLayout={true}
      />
      <DataListTagsURL<PrintOptionsTableColumns, PrintOptionTableItem>
        data={data}
        originalData={normalizedPrintOptions}
      />
      <TableActionsContextProvider<PrintOptionTableItem>
        renderContextActions={(record?: PrintOptionTableItem) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <PrintOptionsTable data={data} />
      </TableActionsContextProvider>

      <PrintOptionsDrawer
        isVisible={isPrintOptionsDrawerVisible}
        onClose={() => navigate(ROUTES.home)}
      />
    </div>
  );
};

export const PrintOptions = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={printOptionsFields}>
        <PrintOptionsContainer />
      </DataListURLProvider>
    </DataProvider>
  );
};
