import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import styles from "./Archive.module.scss";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import {
  ArchiveItem,
  ArchiveTableItem,
} from "shared/types/salesEnablementArchive";
import ArchiveTable from "./archive/ArchiveHistoryTable";
import { DataProvider } from "./hooks/SalesEnablementDataProvider";
import { archiveHistoryFields } from "./archive/archiveHistoryTable/fields";
import { Outlet } from "react-router-dom";
import {
  useDeleteArchives,
  useFetchArchivesWithDetails,
} from "shared/hooks/salesEnablement/useFetchArchives";
import { useState } from "react";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import ArchiveFilterDrawer from "./archive/ArchiveFilterDrawer";
import { arrayToCsv, downloadBlob } from "./utils";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

const { RangePicker } = DatePicker;

const ArchiveTableContainer = () => {
  const isAdmin = useIsAdmin();
  const [viewType, setViewType] = useState<"historyLog" | "templates">(
    "historyLog",
  );

  const [[startDate, endDate], setDateRange] = useState<
    [Moment | null, Moment | null]
  >([moment().subtract(30, "days"), moment()]);

  const { archives, archivesByTemplate, isLoading, originalData } =
    useFetchArchivesWithDetails({
      startDate,
      endDate,
    });

  const { mutate: deleteArchives } = useDeleteArchives();

  const [openFilter, setOpenFilter] = useState(false);

  const navigate = useNavigateWithSearch();

  const { data, selectedItems } = useDataListURLData<
    keyof ArchiveTableItem,
    ArchiveTableItem
  >({
    isError: false,
    isLoading,
    data: viewType === "historyLog" ? archives : archivesByTemplate,
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    keyof ArchiveTableItem,
    ArchiveTableItem
  >();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const onExportClick = () => {
    const archives = originalData?.filter(archive => {
      return selectedItems.some(selected => selected.id === archive.id);
    });
    if (!archives) return;

    const headers: (keyof ArchiveItem)[] = Object.keys(
      archives[0],
    ) as (keyof ArchiveItem)[];
    const values = archives.map(archive =>
      headers.map(header => archive[header] ?? ""),
    );

    const rows = [headers, ...values];
    const csv = arrayToCsv(rows);
    downloadBlob(csv, "export.csv", "text/csv;charset=utf-8;");
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbarContainer}>
        <div className={styles.datePicker}>
          <RangePicker
            value={[startDate, endDate]}
            onChange={dates => {
              if (dates) {
                setDateRange(dates);
              } else {
                setDateRange([null, null]);
              }
            }}
          />
        </div>
        <div className={styles.archiveSearch}>
          <ToolbarTable
            titleTooltip="Search"
            toolbarContents={{
              Export: {
                onClick: onExportClick,
                display: isAdmin,
                disabled: selectedItems.length === 0,
              },
              Delete: {
                onClick: () =>
                  deleteArchives(selectedItems.map(item => item.id)),
                display: isAdmin,
                disabled: selectedItems.length === 0,
              },
            }}
            searchPlaceholder="Search archive"
            onSearch={setGlobalFilter}
            searchValue={globalFilter ?? undefined}
            disableSearch={false}
            previewFormat={false}
            sortingOrder={
              sortKey && sortOrder ? [sortKey, sortOrder] : undefined
            }
            onSortChange={([columnKey, order]) => {
              sortItems(columnKey, order);
            }}
            includeFilterOnTableLayout={true}
          />
        </div>
      </div>
      <DataListTagsURL<keyof ArchiveTableItem, ArchiveTableItem>
        data={data}
        originalData={viewType === "historyLog" ? archives : archivesByTemplate}
        onAddFilterClick={() => setOpenFilter(true)}
      />
      <ArchiveTable
        data={data}
        viewType={viewType}
        setViewType={setViewType}
        loading={isLoading}
        onMaterialClick={record => {
          return navigate(`${record.id}/material`);
        }}
        onTemplateClick={record => {
          if (viewType === "historyLog")
            return navigate(`${record.id}/template`);
          if (viewType === "templates")
            return navigate(`${record.archives?.[0].id}/template`);
        }}
      />
      <ArchiveFilterDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        archives={archives}
      />
      <Outlet />
    </div>
  );
};

const Archive = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={archiveHistoryFields}>
        <ArchiveTableContainer />
      </DataListURLProvider>
    </DataProvider>
  );
};

export default Archive;
