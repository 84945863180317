export const ROUTES = {
  home: "/sales-enablement/templates",
  create: `/sales-enablement/templates/new`,
  edit: (id: string) => `/sales-enablement/templates/${id}`,
  duplicate: (id: string) => `/sales-enablement/templates/${id}/duplicate`,
  newMaterial: (id: string) => `/sales-enablement/templates/${id}/material/new`,
};

export const QUERY_KEYS = {
  templates: "sales-enablement-templates",
};
