import { Template } from "./salesEnablement";

export enum PrintModeOption {
  SIMPLEX = "SIMPLEX",
  DUPLEX = "DUPLEX",
  SIMPLEX_DUPLEX = "SIMPLEX_DUPLEX",
}

export type PrintMode = PrintModeOption.SIMPLEX | PrintModeOption.DUPLEX;

export type PrintOptionsTableColumns =
  | keyof Omit<PrintOptionItem, "costPerCopy" | "id">
  | "copyQuantity"
  | "copyCost";

export type PrintOptionItem =
  | {
      id: string;
      name: string;
      printMode: PrintMode[];
      weight?: number;
      productionTime: PrintOptionsRangeQuantity;
      associatedTemplateIds: string[];
      referenceNumber: string;
    } & (
      | {
          costPerCopy: PrintOptionsCopyFixed[];
          copySelection: PrintOptionCopyType.FIXED;
        }
      | {
          copySelection: PrintOptionCopyType.RANGE;
          costPerCopy: PrintOptionsCopyRange[];
        }
    );

export type PrintOptionTableItem = Omit<PrintOptionItem, "weight"> & {
  weight?: string;
};

export type NestedPrintOptionItem = {
  id: string;
  costPerCopy: {
    quantity: PrintOptionsRangeQuantity | number;
    cost?: number;
  }[];
} & Partial<Omit<PrintOptionTableItem, "id" | "costPerCopy">>;

export type UpdatePrintOptionPayload = Omit<
  PrintOptionItem,
  "associatedTemplateIds"
> & {
  templateUpdates: (Pick<Template, "id"> &
    (
      | { op: "ADD" }
      | ({ op: "REMOVE" } & Pick<Template, "status" | "statusHistory">)
    ))[];
};

export type PrintOptionsCopy<T extends PrintOptionCopyType> = {
  id: string;
  cost: PrintOptionsCopyCost;
  quantity: T extends PrintOptionCopyType.FIXED
    ? number
    : PrintOptionsRangeQuantity;
};

export type PrintOptionsCopyFixed =
  PrintOptionsCopy<PrintOptionCopyType.FIXED> & {
    quantity: number;
  };

export type PrintOptionsCopyRange =
  PrintOptionsCopy<PrintOptionCopyType.RANGE> & {
    quantity: PrintOptionsRangeQuantity;
  };

export type PrintOptionsRangeQuantity = {
  min?: number;
  max?: number;
};

export type PrintOptionsCopyCost = Record<PrintMode, number | undefined>;

export enum PrintOptionCopyType {
  FIXED = "FIXED",
  RANGE = "RANGE",
}
