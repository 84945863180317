import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import { useState } from "react";
import { useDebounceCallback } from "screens/designStudio/hooks/useDebounce";
import InputPhone, { phoneValidator } from "shared/components/InputPhone";
import { states } from "shared/constants/states";
import { useMarketingMaterialDeliveryShippingMethods } from "shared/hooks/adLibrary/marketingMaterials";
import { ShippingData } from "shared/types/marketingMaterials";
import { FedexShippingMethods } from "./FedexShippingMethods";
import styles from "./ShippingDataFields.module.scss";
import { errorNotification } from "shared/components/customNotification/Notification";
import { baseRules } from "screens/adLibrary/marketingMaterialDrawer/utils/helpers";

export const ShippingDataFields = ({
  disabled,
  value: shippingData,
  onChange,
  totalWeight,
  totalAmount,
}: {
  value?: Partial<ShippingData>;
  onChange?: (value: Partial<ShippingData>) => void;
  disabled?: boolean;
  totalWeight: number;
  totalAmount: number;
}) => {
  const [isAdditionalAddressVisible, setIsAdditionalAddressVisible] = useState(
    !!shippingData?.additionalAddress || disabled,
  );
  const [isExtensionVisible, setIsExtensionVisible] = useState(
    !!shippingData?.extension || disabled,
  );

  const {
    data: shippingMethods,
    mutate: getShippingMethods,
    isLoading: isLoadingShippingMethods,
  } = useMarketingMaterialDeliveryShippingMethods();

  const getFedexShippingMethods = useDebounceCallback(getShippingMethods, 1000);

  const validateRequiredShippingFields = (
    shippingFormData: Partial<ShippingData>,
  ): shippingFormData is ShippingData => {
    const { fullName, streetAddress, zip, city, state, phone, email } =
      shippingFormData;
    return Boolean(
      fullName && streetAddress && zip && city && state && phone && email,
    );
  };

  const onChangeShippingField = async (
    fieldName: string,
    fieldData: string,
  ) => {
    const newShippingData: Partial<ShippingData> = {
      ...shippingData,
      [fieldName]: fieldData,
    };
    onChange?.(newShippingData);

    if (newShippingData) {
      if (validateRequiredShippingFields(newShippingData)) {
        getFedexShippingMethods(
          {
            shippingData: {
              ...newShippingData,
              country: "US",
            },
            totalWeight,
            totalAmount,
          },
          {
            onError: () => {
              errorNotification({
                messageLabel:
                  "Unable to fetch shipping methods. Please check the shipping address and try again.",
                size: "big",
              });
            },
          },
        );
      }
    }
  };

  return (
    <div className={styles.col}>
      <Typography.Title level={5} className={styles.title}>
        Shipping Address
      </Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={["shippingData", "fullName"]}
            label="Full Name"
            required
            labelCol={{ span: 20 }}
          >
            <Input
              disabled={disabled}
              onChange={({ target }) =>
                onChangeShippingField("fullName", target.value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["shippingData", "agentNumber"]}
            label="Agent's Writing Number"
            required
            labelCol={{ span: 20 }}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={["shippingData", "streetAddress"]}
        label="Street Address"
        labelCol={{ span: 20 }}
        required
      >
        <Input
          disabled={disabled}
          onChange={({ target }) =>
            onChangeShippingField("streetAddress", target.value)
          }
        />
      </Form.Item>

      {isAdditionalAddressVisible ? (
        <Form.Item
          name="additionalAddress"
          label="Additional Address"
          labelCol={{ span: 20 }}
        >
          <Input
            disabled={disabled}
            suffix={
              <DeleteOutlined
                onClick={() => {
                  setIsAdditionalAddressVisible(false);
                }}
              />
            }
          />
        </Form.Item>
      ) : (
        <Button
          style={{ padding: 0, marginBottom: 16 }}
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setIsAdditionalAddressVisible(true)}
        >
          Add Apartment, Suite, etc. (optional)
        </Button>
      )}

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name={["shippingData", "zip"]}
            label="ZIP"
            labelCol={{ span: 20 }}
            required
          >
            <Input
              disabled={disabled}
              onChange={({ target }) =>
                onChangeShippingField("zip", target.value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={["shippingData", "city"]}
            label="City"
            labelCol={{ span: 20 }}
            required
          >
            <Input
              disabled={disabled}
              onChange={({ target }) =>
                onChangeShippingField("city", target.value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={["shippingData", "state"]}
            label="State"
            labelCol={{ span: 22 }}
            required
          >
            <Select
              disabled={disabled}
              showSearch
              onChange={(value: string) =>
                onChangeShippingField("state", value)
              }
              options={Object.values(states).map(state => ({
                label: `${state.id} - ${state.name}`,
                value: state.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name={["shippingData", "country"]}
        label="Country"
        required
        initialValue="United States"
        labelCol={{ span: 20 }}
      >
        <Input
          value="United States"
          disabled
          onChange={({ target }) =>
            onChangeShippingField("country", target.value)
          }
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={["shippingData", "phone"]}
            label="Phone"
            labelCol={{ span: 20 }}
            required
            className={styles.noMarginField}
            rules={[
              ...baseRules("Please add phone number"),
              {
                validator: (_, value) => phoneValidator(value),
              },
            ]}
          >
            <InputPhone
              disabled={disabled}
              onChange={value => onChangeShippingField("phone", value)}
            />
          </Form.Item>
          <Typography.Text className={styles.helpText} type="secondary">
            For delivery-related calls only
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["shippingData", "email"]}
            label="Email"
            labelCol={{ span: 20 }}
            required
          >
            <Input
              disabled={disabled}
              onChange={({ target }) =>
                onChangeShippingField("email", target.value)
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {isExtensionVisible ? (
        <Form.Item
          name={["shippingData", "extension"]}
          label="Extension"
          labelCol={{ span: 20 }}
        >
          <Input
            disabled={disabled}
            suffix={
              <DeleteOutlined
                onClick={() => {
                  setIsExtensionVisible(false);
                }}
              />
            }
            onChange={({ target }) =>
              onChangeShippingField("extension", target.value)
            }
          />
        </Form.Item>
      ) : (
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setIsExtensionVisible(true)}
        >
          Add Ext.
        </Button>
      )}
      <FedexShippingMethods
        shippingMethods={shippingMethods}
        isLoading={isLoadingShippingMethods}
      />
    </div>
  );
};
