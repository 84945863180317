import usePreference from "./usePreference";

export interface SelectedStore {
  /** The store name. This is the primary key */
  name: string;
  /** The store's associated fb account id */
  fbAccountId: string;
}

export const useSelectedStore = () => {
  const { value: selectedStore, setValue: setSelectedStore } = usePreference<
    SelectedStore | undefined
  >("selectedStore", undefined, true);

  return {
    selectedStore,
    setSelectedStore,
  };
};
