import { useMutation, useQuery } from "react-query";
import API from "services";
import { getResultIfOk } from "services/adLibrary";
import { MarketingMaterialPrintDeliveryOrder } from "shared/types/salesEnablement/order";
import { queryClient } from "queryClient";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";

export const useFetchPrintOrders = () => {
  return useQuery<MarketingMaterialPrintDeliveryOrder[] | undefined, Error>({
    queryKey: [QUERY_KEYS.printArchives],
    queryFn: async () => {
      const { result } = getResultIfOk(
        await API.services.salesEnablement.getPrintOrders(),
      );
      return result;
    },
  });
};

export const useDeletePrintOrders = () => {
  return useMutation({
    mutationFn: API.services.salesEnablement.deletePrintOrders,
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.printArchives]);
    },
  });
};
