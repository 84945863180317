import {
  DeleteOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Alert, Button, Col, Row, Table, Typography, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMemo, useState } from "react";
import { Template } from "shared/types/salesEnablement";
import { useQueryParams } from "use-query-params";
import TemplateTableWrapper from "./associatedTemplates/TemplateTable";
import { uniq } from "lodash";
import { salesEnablementTheme } from "theme/salesEnablement";

import { PreviewTemplate } from "shared/components/salesEnablement/template/PreviewTemplate";

type Props = {
  printOptionId?: string;
  value?: string[];
  onChange?: (associatedTemplateIds: string[]) => void;
  templatesWithPrintOption: Record<string, Template>;
};

export const AssociatedTemplates = ({
  printOptionId,
  value: associatedTemplateIds,
  onChange,
  templatesWithPrintOption,
}: Props) => {
  const [templatePreview, setTemplatePreview] = useState<string>();
  const [, setQuery] = useQueryParams();

  const dataSource = associatedTemplateIds
    ? associatedTemplateIds.flatMap(templateId => {
        const template = templatesWithPrintOption[templateId];
        if (!template) return [];
        return {
          id: templateId,
          name: template.name,
        };
      })
    : [];

  const columns: ColumnsType<{
    id: string;
    name: string;
  }> = [
    {
      title: "Template Name",
      dataIndex: "name",
      width: "95%",
      render: (_, record) => {
        const templatePrintOption =
          templatesWithPrintOption[record.id]?.printOptionId;
        const alreadyAssociated =
          templatePrintOption && templatePrintOption !== printOptionId;
        return (
          <Row justify="space-between">
            <Typography.Link onClick={() => setTemplatePreview(record.id)}>
              {record.name}
            </Typography.Link>
            {alreadyAssociated && (
              <Col>
                <ExclamationCircleFilled
                  style={{
                    color: "orange",
                    marginRight: 4,
                  }}
                />
                <Typography.Text style={{ fontSize: 13 }}>
                  Already Contains print configuration
                </Typography.Text>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "",
      align: "right",
      render: (_, record) => (
        <DeleteOutlined
          onClick={() => {
            if (associatedTemplateIds) {
              Modal.confirm({
                okText: "Disassociate Template",
                title: (
                  <b>
                    Are you sure you want to disassociate this template from
                    Print Option?
                  </b>
                ),
                content: (
                  <>
                    <p>
                      By doing so the template will be <b>unpublished</b> and
                      any materials derived from it will be set to unavailable.
                    </p>
                    <p>
                      You may go to the Templates page to republish e template
                      if needed.
                    </p>
                  </>
                ),
                icon: (
                  <ExclamationCircleOutlined
                    style={{ color: salesEnablementTheme?.primaryColor }}
                  />
                ),
                onOk: () => {
                  onChange?.(
                    associatedTemplateIds.filter(id => id !== record.id),
                  );
                },
              });
            }
          }}
        />
      ),
    },
  ];

  const [tableMode, setTableMode] = useState<"add" | "view">("view");

  const onFinish = (ids: string[]) => {
    onChange?.(uniq([...(associatedTemplateIds ?? []), ...ids]));

    onClose();
  };

  const onClose = () => {
    setQuery({ deliveryMethods: undefined }, "replaceIn");
    setTableMode("view");
  };

  const handleAddRow = () => {
    setTableMode("add");
    setQuery({ deliveryMethods: "print" }, "replaceIn");
  };

  const hasTemplateConfiguration = useMemo(() => {
    return associatedTemplateIds?.some(templateId => {
      const templatePrintOption =
        templatesWithPrintOption[templateId]?.printOptionId;
      return templatePrintOption && templatePrintOption !== printOptionId;
    });
  }, [associatedTemplateIds, printOptionId, templatesWithPrintOption]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {hasTemplateConfiguration && (
          <Alert
            showIcon
            message="Some of the templates are associated with other print options. If you save they will be associated with this one instead."
            type="warning"
          />
        )}
      </Col>
      <Col span={24}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          locale={{ emptyText: "No associated templates" }}
          footer={() => (
            <Button type="link" onClick={handleAddRow} icon={<PlusOutlined />}>
              Select Templates
            </Button>
          )}
        />
        {tableMode === "add" && (
          <TemplateTableWrapper onFinish={onFinish} onClose={onClose} />
        )}
      </Col>
      <PreviewTemplate
        visible={!!templatePreview}
        onClose={() => setTemplatePreview(undefined)}
        templateData={templatesWithPrintOption[templatePreview ?? ""]}
      />
    </Row>
  );
};
