import { QcStatus, IAssetGroup } from "shared/types/adLibrary";
import { AdType, CallToAction } from "./facebookUtils/types";
import { isEnvVarEquals } from "utils/helpers";

const commonStatuses = [QcStatus.DRAFT, QcStatus.ERROR];

export const defaultQcStatuses = [
  QcStatus.CO_OP_APPROVED,
  QcStatus.INTERNALLY_APPROVED,
  QcStatus.AWAITING_APPROVAL,
  QcStatus.DISAPPROVED,
  ...commonStatuses,
];

export const truecarStatuses = [
  QcStatus.TRUECAR_CO_OP_APPROVED,
  QcStatus.TRUECAR_INTERNALLY_APPROVED,
  QcStatus.AWAITING_APPROVAL,
  QcStatus.DISAPPROVED,
  ...commonStatuses,
];

export const bearabyStatuses = [
  QcStatus.CREATIVE_BRIEF_COMPLETE,
  QcStatus.BEARABY_REVIEW,
  QcStatus.CREATIVE_EDITS,
  QcStatus.CREATIVE_APPROVED,
  QcStatus.READY_TO_LOAD,
  ...commonStatuses,
];

export const londreStatuses = [
  QcStatus.CREATIVE_BRIEF_COMPLETE,
  QcStatus.CREATIVE_EDITS,
  QcStatus.CLIENT_REVIEW,
  QcStatus.CREATIVE_APPROVED,
  QcStatus.READY_TO_LOAD,
  ...commonStatuses,
];

export const klarncoStatuses = [
  QcStatus.EDITS_NEEDED,
  QcStatus.CREATIVE_UPDATED,
  QcStatus.AWAITING_APPROVAL,
  QcStatus.APPROVED,
  ...commonStatuses,
];

export const henryRoseStatuses = [
  QcStatus.CREATIVE_BRIEF_COMPLETE,
  QcStatus.CREATIVE_EDITS,
  QcStatus.CLIENT_REVIEW,
  QcStatus.CREATIVE_APPROVED,
  QcStatus.READY_TO_LOAD,
  ...commonStatuses,
];

export const pharmaStatuses = [
  QcStatus.INTERNALLY_APPROVED,
  QcStatus.AWAITING_APPROVAL,
  QcStatus.DISAPPROVED,
  ...commonStatuses,
];

export const callToActionCustomLabels = {
  [CallToAction.BOOK_TRAVEL]: "Book Now",
};

export const callToActionValues = [
  { key: 0, value: CallToAction.LEARN_MORE, text: "Learn More" },
  { key: 1, value: CallToAction.SHOP_NOW, text: "Shop Now" },
  { key: 2, value: CallToAction.GET_OFFER, text: "Get Offer" },
  { key: 3, value: CallToAction.CONTACT_US, text: "Contact Us" },
  { key: 4, value: CallToAction.GET_QUOTE, text: "Get Quote" },
  {
    key: 5,
    value: CallToAction.BOOK_TRAVEL,
    text: callToActionCustomLabels[CallToAction.BOOK_TRAVEL],
  },
  ...(isEnvVarEquals("CLIENT", "philliplim")
    ? [{ key: 6, value: CallToAction.SIGN_UP, text: "Sign Up" }]
    : []),
];

export const dynamicAdList = [AdType.AIA, AdType.DPA, AdType.FTA];

export const GOOGLE_ADS_ASSET_ITEMS_LIMIT: Record<
  | keyof IAssetGroup["uploads"]
  | keyof IAssetGroup["multiInputAsset"]
  | "videoAssets",
  number
> = {
  landscapeImages: 20,
  squareImages: 20,
  portraitImages: 20,
  squareLogos: 5,
  landscapeLogos: 5,
  headlines: 5,
  longHeadlines: 5,
  descriptions: 5,
  videoAssets: 5,
  youtubeVideoUrls: 5,
};

export const fileSizeLimits = {
  video: 300, //300MB
  image: 8, //8MB
};

export const imageAssetDetail = {
  "Landscape Images": {
    ratio: 1.91,
    info: `(1.91:1) 600x316 min 1200x628 recommended 5120kb max file size`,
  },
  "Square Images": {
    ratio: 1,
    info: "(1:1) 300x300 min 1200x1200 recommended 5120kb max file size",
  },
  "Portrait Images": {
    ratio: 0.8,
    info: "(4:5) 480x600 min 960x1200 recommended 5120kb max file size",
  },
  "Square Logos": {
    ratio: 1,
    info: "(1:1) 128x128 min 1200x1200 recommended 5120kb max file size",
  },
  "Landscape Logos": {
    ratio: 4,
    info: "(4:1) 512x128 min 1200x300 recommended 5120kb max file size",
  },
};

export const multiInputAssetDetail = {
  Headlines: {
    minimum: 3,
    info: "",
  },
  "Long Headlines": {
    minimum: 1,
    info: "",
  },
  Descriptions: {
    minimum: 2,
    info: "",
  },
  "Youtube Video URLs": {
    minimum: 0,
    info: "horizontal, vertical or square min 10 seconds in length",
  },
};

export const localStorageLayoutKey = "adLibraryLayout";
