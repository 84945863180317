import { queryClient } from "queryClient";
import { useMutation } from "react-query";
import { QUERY_KEYS as PRINT_OPTIONS_QUERY_KEYS } from "screens/designStudio/printOptions/constants";
import { QUERY_KEYS as TEMPLATE_QUERY_KEYS } from "screens/designStudio/templates/constants";
import API from "services";
import { errorNotification } from "shared/components/customNotification/Notification";

export const useUpdatePrintOption = () => {
  return useMutation({
    mutationFn: API.services.salesEnablement.updatePrintOption,
    onSettled: async (_, __, variables) => {
      if (variables.option.templateUpdates.length) {
        await queryClient.invalidateQueries([
          PRINT_OPTIONS_QUERY_KEYS.printOptions,
        ]);
        await queryClient.invalidateQueries([TEMPLATE_QUERY_KEYS.templates]);
      } else {
        queryClient.invalidateQueries([PRINT_OPTIONS_QUERY_KEYS.printOptions]);
        queryClient.invalidateQueries([TEMPLATE_QUERY_KEYS.templates]);
      }
    },
    onError: () => {
      errorNotification({
        messageLabel: "Something went wrong updating the print option.",
        size: "big",
      });
    },
  });
};
