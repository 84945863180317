import { Modal, Drawer, Button, Form, Divider } from "antd";
import { FC, memo, useMemo, useState } from "react";
import { AdType } from "./facebookUtils/types";
import { IAd, ICard, IAdForm, QcStatus } from "shared/types/adLibrary";
import AdCreate from "./adWizard/AdCreate";
import AdPreview from "./adWizard/AdPreview";
import AdParameters from "./adWizard/AdParameters";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "shared/hooks/useUser";
import { useOptimisticallyMutateAd } from "shared/hooks/adLibrary/useMutateAd";
import { useForm } from "antd/lib/form/Form";
import CommentsOpenerWithData from "shared/button/CommentsOpenerWithData";
import { useBatchCreateNotification } from "shared/hooks/notification/useMutateNotification";
import { IndustryType } from "shared/types/shared";
import styles from "./AdWizard.module.scss";
import { INotification } from "shared/types/notification";
import {
  defaultCards,
  getInitialValues,
  areFieldsRequired as required,
  getAdFromState,
  getAreMediaValuesValid,
  getIsRunDatesValid,
} from "./adWizard.utils";
import { useValidateAd } from "./shared/validationUtils";
import { AdMedia } from "shared/types/uploadManagement";
import { getAdMediaFromVisuals } from "./utils";
import { shouldEnableNotifications } from "utils/constants";

interface IProps {
  onClose: () => void;
  adEdit?: IAd;
  initialActiveTab?: ActiveKeyType;
  onAdEditSuccess?: () => void;
  getFooterButtons?: (props: {
    validateOnSubmit: (onValidate: (ad: IAd) => void) => void;
    createNotifications: () => void;
  }) => JSX.Element;
}

export type ActiveKeyType = "create" | "preview";
export type CarouselCollapseState = {
  panel: string | string[] | undefined;
  slide: number;
};

const AdWizard: FC<IProps> = ({
  onClose,
  adEdit,
  onAdEditSuccess,
  getFooterButtons,
}) => {
  const [adCreate, setAdCreate] = useState<IAd | null | undefined>(adEdit);
  const { validateAd } = useValidateAd();
  const initialValues = useMemo(() => getInitialValues(adEdit), [adEdit]);
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState(false);
  const [adType, setAdType] = useState(adEdit?.type || initialValues.type);
  const user = useUser();

  const [form] = useForm<IAdForm>();

  const [previewDisabled, setPreviewDisabled] = useState(true);

  const [qcStatusChange, setQcStatusChange] = useState<{
    detail: QcStatus;
    change: QcStatus;
  }>();

  const [carouselCollapse, setCarouselCollapse] =
    useState<CarouselCollapseState>({ panel: undefined, slide: 0 });
  const { mutate: mutateAd, isLoading: isMutatingAd } =
    useOptimisticallyMutateAd();

  const { mutate: batchCreateNotifications } = useBatchCreateNotification();

  const [clientType, setClientType] = useState<IndustryType>(
    initialValues.industry,
  );

  const adId = useMemo(() => adEdit?.id ?? uuidv4(), [adEdit?.id]);

  const [multiFileUrls, setMultiFileUrls] = useState<AdMedia[]>(
    getAdMediaFromVisuals(adEdit),
  );

  const [cards, setCards] = useState<ICard[]>(
    adEdit?.visuals?.cards ?? defaultCards,
  );

  const [formValues, setFormValues] = useState(initialValues);
  const adDraft = useMemo(
    () =>
      getAdFromState({
        formValues,
        adEdit,
        user,
        adId,
        cards,
        fileUrls: multiFileUrls[0],
        multiFileUrls: multiFileUrls.slice(1),
      }),
    [formValues, adEdit, user, adId, cards, multiFileUrls],
  );

  const isValid = useMemo(() => {
    if (!adDraft) return true;

    return validateAd(adDraft).isValid;
  }, [adDraft, validateAd]);

  const openCarouselCollapseOnFirstError = (errors: { name: string[] }[]) => {
    const isCarousel = adType === AdType.Carousel;
    const firstCardErrorFound =
      isCarousel && errors.find(e => e.name[0] === "cards");
    if (!firstCardErrorFound) return;

    const [_, slide = 1, _field, panel = "1:1"] = firstCardErrorFound.name;
    const p = panel === "IN_FEED" ? "1:1" : panel === "STORY" ? "9:16" : panel;
    setCarouselCollapse({ panel: p ?? "1:1", slide: Number(slide) });
  };

  const validateAndCall = async (
    message: string,
    callback: (adUpdate: IAd) => void,
    skipValidations?: string[],
    verbose?: boolean,
  ) => {
    setShouldDisplayErrors(required);
    const errors: string[] = [];
    try {
      const newFormValues: IAdForm = await form.validateFields().catch(e => {
        const invalidFieldFound = e.errorFields.find(
          (field: { errors: string[]; name: string[] }) =>
            !skipValidations?.includes(field.name[1]),
        );
        if (!!invalidFieldFound) {
          openCarouselCollapseOnFirstError(e.errorFields);
          errors.push(...invalidFieldFound.errors);

          throw e;
        }
        return e.values;
      });
      const areMediaValuesValid =
        skipValidations?.includes("media") ||
        getAreMediaValuesValid({
          adType: form.getFieldValue("type"),
          cards,
          fileUrls: multiFileUrls,
        });
      if (!areMediaValuesValid) throw Error("Invalid media values");

      const isRunDatesValid =
        !form.getFieldValue("runDates") ||
        skipValidations?.includes("runDates") ||
        getIsRunDatesValid({
          runDates: form.getFieldValue("runDates"),
        });
      if (!isRunDatesValid) throw Error("Invalid run dates");

      const updatedAd = getAdFromState({
        formValues: newFormValues,
        adEdit,
        user,
        adId,
        cards,
        fileUrls: multiFileUrls[0],
        multiFileUrls: multiFileUrls.slice(1),
      });

      if (
        required &&
        !updatedAd.inputParameters?.destinationUrl &&
        [AdType.Still, AdType.Video].includes(updatedAd.type)
      ) {
        Modal.confirm({
          title: "Confirm Changes",
          content: `Destination URL is empty, but it is required when running Ad Load on ${updatedAd.type} ads. Do you still want to save the latest changes?`,
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            setAdCreate(updatedAd);
            callback(updatedAd);
          },
        });
        return;
      }
      setAdCreate(updatedAd);
      callback(updatedAd);
    } catch (_) {
      Modal.error({
        title: "Error Detected",
        content:
          !verbose && !!errors ? (
            message
          ) : (
            <div>
              {message}
              <ul className={styles["error-description"]}>
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            </div>
          ),
        okText: "Go Back",
      });
    }
  };

  const createNotifications = () => {
    if (
      shouldEnableNotifications &&
      qcStatusChange &&
      adEdit?.userHistory?.length
    ) {
      const notificationObj: INotification = {
        user: "",
        createdBy: `${user?.name}`,
        type: "updateStatus",
        module: "Ad Library",
        feature: `${adEdit?.inputParameters.name}`,
        detail: qcStatusChange.detail,
        change: qcStatusChange.change,
        link: `/ad-library?id=${adId}`,
      };
      const notificationsObj = adEdit.userHistory
        .filter(pUser => pUser !== user?.email)
        .map(userToNotify => {
          return { ...notificationObj, user: userToNotify };
        });
      batchCreateNotifications(notificationsObj);
    }
  };

  const submitValidate = (onValidate: (ad: IAd) => void) => {
    validateAndCall(
      "There is an error in your ad. Please fix all errors before saving.",
      onValidate,
      [
        "postCopy",
        "headline",
        "headlineDescription",
        "destination",
        "primaryText",
        "ctaButtonText",
        "utmCode",
        "media",
        "destinationUrl",
      ],
    );
  };

  const onFormSubmit = () => {
    createNotifications();
    submitValidate(updatedAd => {
      mutateAd(updatedAd, {
        onSuccess: () => {
          onClose();
          onAdEditSuccess?.();
        },
      });
    });
  };

  const defaultFooterButtons = [
    <CommentsOpenerWithData
      id={adId}
      key="comments"
      mode={!!adEdit ? "edit" : "create"}
      extraInfo={{ title: adEdit?.inputParameters.name }}
    />,
    <Button key="cancel" onClick={onClose} data-cy="ad-wizard-cancel-button">
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      form="ad-form"
      htmlType="submit"
      loading={isMutatingAd}
      data-cy="ad-wizard-save-button"
    >
      Save
    </Button>,
  ];

  const footer =
    getFooterButtons?.({
      validateOnSubmit: submitValidate,
      createNotifications,
    }) || defaultFooterButtons;

  return (
    <Drawer
      data-cy="ad-wizard-drawer"
      className={styles.adWizard}
      visible={true}
      closable={false}
      maskClosable={false}
      width={"95%"}
      onClose={onClose}
      footer={footer}
    >
      <Form<IAdForm>
        initialValues={initialValues}
        name="ad-wizard"
        form={form}
        layout="vertical"
        id="ad-form"
        onFinish={() => {
          onFormSubmit();
        }}
        onFinishFailed={() => {
          onFormSubmit();
        }}
        onValuesChange={(_, allValues) => {
          setPreviewDisabled(false);
          setFormValues(allValues);
        }}
      >
        <AdCreate
          adEdit={adEdit}
          setPreviewDisabled={setPreviewDisabled}
          multiFileUrls={multiFileUrls}
          setMultiFileUrls={setMultiFileUrls}
          shouldDisplayErrors={shouldDisplayErrors}
          adType={adType}
          setAdType={setAdType}
          setClientType={setClientType}
          cards={cards}
          setCards={setCards}
          carouselCollapse={carouselCollapse}
          setCarouselCollapse={setCarouselCollapse}
        />
        <Divider type="vertical" />
        <AdPreview
          adDraft={adDraft}
          ad={adCreate}
          previewDisabled={previewDisabled}
          setPreviewDisabled={setPreviewDisabled}
          validateAndCall={validateAndCall}
        />
        <Divider type="vertical" />
        <AdParameters
          adEdit={adEdit}
          form={form}
          clientType={clientType}
          adType={adType}
          setQcStatusChange={setQcStatusChange}
          isValid={isValid}
        />
      </Form>
    </Drawer>
  );
};

export default memo(AdWizard);
