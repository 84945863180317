import { createRef, memo, useEffect, useState } from "react";
import { Select } from "antd";
import LoadingContent from "shared/components/select/LoadingContent";
import { formatOption } from "utils/helpers";

import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";

import { IAccount } from "shared/types/accountManagement";
import { RefSelectProps } from "antd/lib/select";

export type AccountFilters = {
  enabled?: boolean;
  hasFbAccount?: boolean;
  hadBeeswaxAdvertiser?: boolean;
};

interface IProps {
  value?: string | string[];
  defaultValue?: string | string[];
  autoFocus?: boolean;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  mode?: "multiple";
  style?: React.CSSProperties;
  className?: string;
  onSelect?(value: string, dealers?: IAccount[]): void;
  onDeselect?(value: string, dealers?: IAccount[]): void;
  onChange?(value: string | string[], dealers?: IAccount[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  filters?: AccountFilters;
}

function GenericAccountSelect({
  value,
  defaultValue,
  autoFocus,
  loading,
  disabled,
  placeholder,
  mode,
  style,
  className,
  onChange,
  onSelect,
  onDeselect,
  onFocus,
  filters,
}: IProps) {
  const [willKeepOpen, setWillKeepOpen] = useState(false);
  const [preventOpenBool, setPreventOpenBool] = useState(false);
  const {
    dealers,
    isLoading: isLoadingDealers,
    isFetchingNextPage,
    hasNextPage,
  } = useFetchInfiniteDealers();
  const loadingData = isLoadingDealers || isFetchingNextPage || hasNextPage;
  const allDealers = loadingData ? [] : dealers;
  const filteredDealers = allDealers.filter(dealer => {
    if (filters?.enabled && !dealer.enabled) {
      return false;
    }

    if (filters?.hasFbAccount && !dealer.details?.facebook?.fbAccountId) {
      return false;
    }

    if (
      filters?.hadBeeswaxAdvertiser &&
      !dealer.details?.beeswax?.advertiserId
    ) {
      return false;
    }

    return true;
  });
  const selectRef = createRef<RefSelectProps>();
  const isOpen = !preventOpenBool ? willKeepOpen : undefined;

  useEffect(() => {
    return () => {
      setWillKeepOpen(false);
    };
  }, []);

  useEffect(() => {
    if (!autoFocus || !!value?.length) return;
    // need to wait for the drawer to open before showing the select
    setTimeout(() => {
      selectRef?.current?.focus();
      setWillKeepOpen(true);
    }, 100);
  }, [autoFocus, loadingData, selectRef, value?.length]);

  return (
    <Select
      id="accountSelect"
      data-cy="account-select"
      ref={selectRef}
      showSearch
      value={value ?? []}
      defaultValue={defaultValue}
      style={style}
      open={autoFocus ? isOpen : undefined}
      placeholder={placeholder}
      onBlur={() => {
        if (!autoFocus) {
          return;
        }
        setWillKeepOpen(false);
      }}
      onFocus={e => {
        onFocus?.(e);
        if (autoFocus) {
          setWillKeepOpen(true);
        }
      }}
      onSelect={val => {
        if (autoFocus) {
          setPreventOpenBool(true);
        }
        onSelect?.(val, allDealers);
      }}
      onDeselect={val => onDeselect?.(val, allDealers)}
      onChange={val => {
        onChange?.(val, allDealers);
      }}
      loading={loadingData || loading}
      disabled={disabled || loading}
      notFoundContent={loadingData || loading ? <LoadingContent /> : undefined}
      mode={mode}
      className={className}
    >
      {filteredDealers.map(({ dealer_name }) => [
        <Select.Option
          key={dealer_name}
          value={dealer_name}
          data-cy={`option-${formatOption(dealer_name)}`}
        >
          {dealer_name}
        </Select.Option>,
      ])}
    </Select>
  );
}

export default memo(GenericAccountSelect);
