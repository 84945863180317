import { onConfirmPublish } from "../shared/components/publish";
import { useMutation, useQueryClient } from "react-query";
import { Template, TemplateStatus } from "shared/types/salesEnablement";
import API from "services";
import { successNotification } from "shared/components/customNotification/Notification";
import { QUERY_KEYS } from "../templates/constants";

export const useToggleTemplateStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (form: Partial<Record<keyof Template, any>>) =>
      API.services.salesEnablement.saveTemplate(form, "edit"),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEYS.templates);
      },
    },
  );

  const updateStatus = async ({
    status,
    record,
  }: {
    status: TemplateStatus;
    record: Template;
  }) => {
    await mutation.mutateAsync({
      ...record,
      status,
      statusHistory: [...record.statusHistory, status],
    });

    successNotification({
      messageLabel: `The template has been ${status.toLowerCase()}`,
      size: "big",
    });
  };

  return async ({ record }: { record: Template }) => {
    if (!record) return;

    if (record.status === TemplateStatus.UNPUBLISHED) {
      return onConfirmPublish({
        onOk: async () =>
          updateStatus({ status: TemplateStatus.PUBLISHED, record }),
      });
    }

    await updateStatus({ status: TemplateStatus.UNPUBLISHED, record });
  };
};
