import { InputNumber } from "antd";
import styles from "./CostInput.module.scss";

type CostInputProps = {
  value?: number;
  onChange?: (value: number) => void;
};
export const CostInput = ({
  value: currentValue,
  onChange,
}: CostInputProps) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.metaKey) return;
    if ([" "].includes(e.key)) return e.preventDefault();
  };

  return (
    <InputNumber
      value={currentValue}
      onChange={onChange}
      className={styles.inputNumber}
      min={0}
      onKeyDown={onKeyDown}
      prefix="$"
    />
  );
};
